import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | ZeusX Exchange',
  defaultTitle: 'ZeusX Exchange',
  description: 'Trade, earn, and own crypto on the all-in-one multichain DEX',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@zeusX',
    site: '@zeusX',
  },
  openGraph: {
    title: "ZeusX Exchange - Everyone's Favorite DEX",
    description: 'Trade, earn, and own crypto on the all-in-one ZeusX DEX',
    images: [{ url: 'https://zeuschainscan.io/static/og.png' }],
  },
}
