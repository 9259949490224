import { Language } from "../LangSelector/types";
import { DiscordIcon, GithubIcon, TelegramIcon, TwitterIcon } from "../Svg";
import { FooterLinkType } from "./types";

export const footerLinks: FooterLinkType[] = [];

export const socials = [
  {
    label: "Twitter",
    icon: '/social/x.svg',
    href: "https://x.com/ZEUSxPay",
  },
  {
    label: "Telegram",
    icon: '/social/tg.svg',
    href: "https://t.me/ZEUS_bp",
  },
  {
    label: "Discord",
    icon: '/social/discord.svg',
    href: "https://discord.com/invite/3BuCKqc6dF",
  },
  {
    label: "The Alley",
    icon: '/social/alleyLogo.png',
    href: "https://www.thealley.io/profile/zeuschain/profile",
  },
];

export const langs: Language[] = [...Array(20)].map((_, i) => ({
  code: `en${i}`,
  language: `English${i}`,
  locale: `Locale${i}`,
}));
